const CACHE = "aqumen-portal-2025-02-002";
const PRE_CACHE_FILES = [
  "index.html",
  "index.js"
];

self.addEventListener("install", (event) => {
  console.debug("QWA Service Worker: Installing.");
  const caching = caches.open(CACHE).then((c) => c.addAll(PRE_CACHE_FILES));
  event.waitUntil(caching.then(() => self.skipWaiting()));
});

self.addEventListener("activate", (event) => {
  console.debug("QWA Service Worker: Activating.");
  event.waitUntil(
    caches.keys().then((keys) => {
      return Promise.all(keys.map((key) => {
        return (CACHE !== key) ? caches.delete(key) : true;
      }));
    })
  );
  return self.clients.claim();
});

self.addEventListener("fetch", (event) => {
  if (!event.request.url) {
    console.debug("QWA Service Worker ignoring request: no URL in event.request", event);
    return;
  }

  if (event.request.method !== "GET") {
    console.debug(`QWA Service Worker ignoring ${event.request.method} ${event.request.url}: not a GET.`);
    return;
  }

  if (!event.request.url.startsWith("https://")) {
    console.warn(`QWA Service Worker ignoring ${event.request.method} ${event.request.url}: not https://*`);
    return;
  }

  if (!/\.(svg|png|js|json|html)$/.test(event.request.url)) {
    console.debug(`QWA Service Worker ignoring ${event.request.method} ${event.request.url}: not a cached file extension.`);
    return;
  }

  if (/(\.local|localhost)/.test(event.request.url)) {
    console.debug(`QWA Service Worker ignoring ${event.request.method} ${event.request.url}: is local or localhost.`);
    return;
  }

  console.debug("QWA Service Worker: using cache.", event.request.url);
  event.respondWith(
    caches.match(event.request).then((cached) => {
      return cached || fetch(event.request).then((fetched) => {
        console.debug("QWA Service Worker: cache miss.", event.request.url);
        return caches.open(CACHE).then((cache) => {
          cache.put(event.request, fetched.clone());
          console.debug("QWA Service Worker: cached new.", event.request.url);
          return fetched;
        });
      });
    })
  );
});
